






















































































































































































































































import Base from '@/mixins/Base.vue';
import { IObjectCategoriesResponse, IObjectCategory } from '@/interfaces/objectcategory';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    data() {
        return {
            categoriesParams: {
                active: null,
                source: null,
                name: '',
                sortBy: 'name',
                orderBy: 'asc',
                page: 1,

            },
            categories: {
                data: [] as Array<IObjectCategory>,
                meta: {} as IPagination | any,
            } as IObjectCategoriesResponse,
        };
    },
    mounted() {
        this.getCategories();
    },
    methods: {
        getCategories(page: number = 1): void {
            this.categoriesParams.page = page;

            this.get<IObjectCategoriesResponse>('object-categories', {
                params: {
                    ...this.categoriesParams,
                },
            }).then(({ data }) => {
                this.categories = data;
            });
        },
    },
});

export default component;
